










import { Component, Vue, Prop } from "vue-property-decorator";
import PwChangeForm from "@/components/PwChangeForm.vue";

@Component({
  components: {
    PwChangeForm,
  },
})
export default class PwChange extends Vue {
  @Prop() code!: string;
}
