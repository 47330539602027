













import { Component, Vue, Prop } from "vue-property-decorator";
import { loginModule, PwChangeState } from "../store/modules/LoginModule";
import PwChangeFormView from "./PwChangeFormView.vue";
import PwFormFinishedView from "./PwFormFinishedView.vue";

@Component({
  components: {
    PwChangeFormView,
    PwFormFinishedView,
  },
})
export default class PwChangeForm extends Vue {
  @Prop() code!: string;

  get state(): PwChangeState {
    return loginModule.pwChangeState;
  }

  get loading(): boolean {
    return this.state == "loading";
  }

  get done(): boolean {
    return this.state == "changed";
  }

  get error(): boolean {
    return this.state == "error";
  }

  mounted() {
    loginModule.changePassword({ code: this.code });
  }

  request(password: string) {
    loginModule.changePassword({ code: this.code, password });
  }

  cancel() {
    this.$router.push({ name: "Login" });
  }
}
