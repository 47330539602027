
















































import { Component, Vue, Prop, Emit } from "vue-property-decorator";

@Component
export default class PwChangeFormView extends Vue {
  @Prop() loading!: boolean;

  private password1 = "";

  private password2 = "";

  private show = false;

  get disabled() {
    return this.password1 == "" || this.password1 != this.password2;
  }

  @Emit()
  request(): string {
    return this.password1;
  }

  @Emit()
  cancel() {}
}
